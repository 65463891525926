import Colors from '@app/themes/colors';
import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles({
  dialog: {
    width: '100%',
    height: '60%',
  },
  boxtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    padding: 0,
    marginLeft: 15,
    marginTop: 10,
  },
  box: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    padding: 10,
  },
  paperButton: {
    border: `3px solid ${Colors.primary}`,
    backgroundColor: Colors.white,
    borderRadius: '5px',
    width: '150px',
    height: '40px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.primary,
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
    },
  },
  applyButton: {
    border: `3px solid ${Colors.primary}`,
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: '150px',
    height: '40px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.white,
    cursor: 'pointer',
    '&:hover': {
      background: Colors.primary,
    },
  },
  root: {
    flexDirection: 'row-reverse',
  },
  datePicker: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'transparent',
      },
    },
  },
  subText: {
    whiteSpace: 'break-spaces',
    fontStyle: 'italic',
    fontSize: 11,
    marginLeft: 15,
    color: Colors.grey[500],
  },
});
