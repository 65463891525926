import {
  applyFilterWatcher,
  applySetDefaultFilterWatcher,
} from '@app/components/right-sidebar/redux/filterHandlerSaga';
import {fetchRoutesDataWatcher} from '@app/router/redux/routeHandlerSaga';
import {
  accessGroupMenuItemsWatcher,
  addAccessGroupWatcher,
  disableAccessGroupWatcher,
  editAccessGroupWatcher,
  fetchAccessGroupMasterWatcher,
  fetchAccessGroupsAutoSuggestDataWatcher,
} from '@app/screens/access-management/pages/access-group-master/redux/saga';
import {
  fetchInventoryCnfReturnWatcher,
  fetchCNFStatesAndDivisionsWatcher,
  fetchCNFHeadquartersWatcher,
  fetchCnfAutoSuggestionWatcher,
  fetchFilteredDataWatcher,
  fetchCnfReturnItemsFilterWatcher,
  fetchApprovedItemsWatcher,
  fetchRejectItemsWatcher,
  fetchCnfAuditDivisionWatcher,
  fetchCnfReturnAudtilDetialsWatcher,
} from '@app/screens/inventory-management/pages/cnf-return-items/redux/saga';
import {
  fetchInventoryIntransistChallansWatcher,
  fetchAutoSuggestionWatcher,
  fetchInTransitFilteredDataWatcher,
  postIntransitChallanOpenCloseWatcher,
  enableDisableIntransitChallansWatcher,
  fetchVoucherNumberDataWatcher,
  fetchRecievedChallansDataWatcher,
  fetchIntransitChallanDivivionsWatcher,
  fetchChallanAuditDataWatcher,
  fetchChallanAuditReportWatcher,
  fetchChallanReceivesearchDataWatcher,
  fetchChallanReceiveAutoSuggestWatcher,
} from '@app/screens/inventory-management/pages/intransit-challan/redux/saga';

import {
  fetchInventoryApproveReturnWatcher,
  fetchApproveStatesAndDivisionsWatcher,
  fetchApproveHeadquartersWatcher,
  fetchApproveReturnItemsAutoSuggestionWatcher,
  fetchApproveFilteredDataWatcher,
  fetchApproveReturnItemsFilterWatcher,
  fetchApprovedReturnItemsWatcher,
  fetchApproveRejectItemsWatcher,
} from '@app/screens/inventory-management/pages/ApprovedReturnedItems/redux/saga';
import {
  editAdminUserWatcher,
  fetchAccessGroupDropDownWatcher,
  fetchAdminUsersDataWatcher,
  fetchDivisionDropdownWatcher,
  fetchStateDropdownWatcher,
  addAdminUserWatcher,
  disableAdminUserWatcher,
  fetchAdminUsersAutoSuggestDataWatcher,
  fetchAdminAddUsersAutoSuggestDataWatcher,
} from '@app/screens/access-management/pages/user-management/redux/saga';

import {
  fetchPlanAndMeetStatusListWatcher,
  updateMTPStatusWatcher,
  BulkGSPUpdateWatcher,
  bulkSTPUpdateWatcher,
  bulkMTPUpdateWatcher,
  bulkDCRPUpdateWatcher,
  fetchAllHierarchyWatcher,
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';

import {all, call, spawn} from 'redux-saga/effects';
import {
  fetchCannotAccessModalUsersWatcher,
  fetchNotificationsWatcher,
  fetchUserInfoWatcher,
  markNotificationReadWatcher,
  fetchUserAppSettingsWatcher,
} from '@app/store/sagas/appSaga';
import {
  fetchHqsWatcher,
  fetchMTPRecommendationsWatcher,
  filterBasedDownloadExcelWatcher,
  fetchPreferredFiltersWatcher,
  fetchMTPUnlockReasonsWatcher,
  enableDCRWatcher,
  fetchPlanAndMeetHierarchyWatcher,
  HierarchyBasedDownloadExcelWatcher,
  webAccessUnlockWatcher,
  getWebAccessStatusWatcher,
  enableUnfilledDCRWatcher,
  bulkSTPUnlockWatcher,
  bulkMTPUnlockWatcher,
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux/saga';
import {
  fetchDcrUnlockRequestListWatcher,
  fetchDCRUnlockReasonsWatcher,
  approveDCRUnlockRequestWatcher,
  fetchDCRHqsWatcher,
  dcrDownloadExcelWatcher,
  dcrBulkUnlockWatcher,
} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux';
import {
  fetchPartiesBySpidWatcher,
  fetchPartyDetailBySpidWatcher,
  fetchCloningPeriodWatcher,
  transferPositionUpdateWatcher,
  fetchPositionCloningListWatcher,
  clonePositionUpdateWatcher,
  fetchPositionTransferListWatcher,
  validatePartyTransferWatcher,
  areaAllocationWatcher,
  fetchAreasBySpidWatcher,
} from '@app/screens/module-specific-utilities/pages/position-management/redux';
import {
  fetchApprovalWorkflowListWatcher,
  fetchMasterConfigDataWatcher,
  createApprovalResponseWatcher,
} from '@app/screens/configuration-management/pages/approval-workflow/redux';
import {updateMinApproverConfigWatcher} from '@app/screens/configuration-management/pages/approval-workflow/redux/saga';
import {modifyDCRRequestWatcher} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux/saga';
import {
  fetchInventoryConfiguratorCustomerTypeWatcher,
  fetchInventoryConfiguratorDivisionWatcher,
  fetchInventoryConfiguratorDoctorQualificationWatcher,
  fetchInventoryConfiguratorDoctorSpecialityWatcher,
  fetchInventoryConfiguratorRegionHeirarcyWatcher,
  fetchInventoryConfiguratorSpecCategoryWatcher,
  fetchInventoryConfiguratorClassCodeWatcher,
  fetchInventoryConfiguratorRolesWatcher,
  fetchInventoryConfiguratorItemCategoryWatcher,
  fetchInventoryConfiguratorItemCodeWatcher,
  fetchInventoryConfiguratorSetValueWatcher,
  fetchInventoryConfiguratorQualificationWatcher,
} from '@app/screens/inventory-management/pages/inventoryConfigurator/redux/saga';
import {
  fetchExpectedUtilizatonDateSkuAutoSuggestionWatcher,
  fetchExpectedUtilizatonDaysWatcher,
  fetchNewExpectedUtilizatonDaysWatcher,
} from '@app/screens/inventory-management/pages/Expected Utilizaton Date/redux/saga';
import {
  fetchItemCategoryWatcher,
  fetchInventoryDeleteRolesWatcher,
  fetchInventorySaveRolesWatcher,
  fetchInventoryDivisionWatcher,
  fetchInventoryRolesPage2Watcher,
  fetchInventorySaveRolesPage2Watcher,
  fetchInventoryDeleteRolesPage2Watcher,
  fetchInventoryPartyTypeWatcher,
  fetchItemCategoryUserWatcher,
  fetchInventoryRulesActiveInActiveWatcher,
  fetchInventoryRulesActiveWatcher,
  fetchInventoryRulesInActiveWatcher,
} from '@app/screens/inventory-management/pages/inventoryRules/redux/saga';
import {
  fetchInTransitAccessDivisionStateWatcher,
  fetchInTransitAccessUserWatcher,
  fetchInTransitAccessDivisionLockedWatcher,
  fetchInTransitAccessStateLockedWatcher,
  fetchInTransitAccessUserLockedWatcher,
  fetchInTransitAccessUserStateDivisionRoleWatcher,
} from '@app/screens/inventory-management/pages/in-transit-access/redux/saga';
import {
  fetchStateAndDivisionWatcher,
  fetchHeadquaterWatcher,
  fetchPendencyReportWatcher,
  fetchDivisioinStateDataWatcher,
  fetchItemSampleAutoSuggestWatcher,
  fetchPendencySubordinatesWatcher,
  fetchUserListDataWatcher,
  fetchItemAndSampleWatcher,
} from '@app/screens/inventory-management/pages/PendencyReport/redux/saga';
import {
  unmappedActivityListWatcher,
  mappedActivityListWatcher,
  saveActivityMappingWatcher,
  fetchDivisionMappingWatcher,
  fetchActivitiesWatcher,
  saveActivitiesWatcher,
} from '@app/screens/configuration-management/pages/activity-mapping/redux';
import {
  fetchChallanShortQtyApprovalDataListWatcher,
  fetchVoucherDetailsListWatcher,
  fetchChallanShortQtyApprovalWatcher,
  fetchChallanShortQtyRejectWatcher,
  FetchAutoSuggestionChallanWatcher,
  fetchChallanShortQuantityAuditDivivionsWatcher,
  fetchAutoSuggestionChallanShortQtyWatcher,
  fetchChallanShortQuantityAuditTrailDataWatcher,
  fetchChallanAuditTrailExcelSheetWatcher,
} from '@app/screens/inventory-management/pages/ChallanShortQtyApproval/redux/saga';
import {fetchGspScheduleListWatcher} from '@app/screens/configuration-management/pages/gsp-schedule/redux';
import {
  fetchGspDivisionsWatcher,
  saveConfigurationWatcher,
  validatePrerequisitesWatcher,
} from '@app/screens/configuration-management/pages/gsp-schedule/redux/saga';

import {fetchAutoSuggestListForStaffNameWatcher} from '@app/screens/inventory-management/pages/ledgerReport/redux/saga';
import {
  fetchDataCorrectionListWatcher,
  updateDataCorrectionWatcher,
} from '@app/screens/master-data-management/pages/data-correction/redux';

import {
  fetchAllowedHolidayByCountryYearWatcher,
  fetchCountryWatcher,
  fetchHolidatByYearWatcher,
  fetchHolidayDivisionWatcher,
  fetchStateeWatcher,
} from '@app/screens/holiday-management/redux/saga';
import {
  fetchGetAllBalanceLeaveApiWatcher,
  fetchLeaveHistoryWatcher,
  fetchReportersApiWatcher,
} from '@app/screens/leave-management/redux';
export function* rootSaga() {
  /**
   *
   *  Register watchers
   *
   */
  const sagas: any = [
    fetchRoutesDataWatcher,
    fetchUserInfoWatcher,
    fetchAdminUsersDataWatcher,
    fetchAccessGroupMasterWatcher,
    fetchAccessGroupDropDownWatcher,
    fetchDivisionDropdownWatcher,
    fetchStateDropdownWatcher,
    editAdminUserWatcher,
    addAdminUserWatcher,
    disableAdminUserWatcher,
    fetchAdminUsersAutoSuggestDataWatcher,
    fetchAdminAddUsersAutoSuggestDataWatcher,
    fetchCannotAccessModalUsersWatcher,
    applyFilterWatcher,
    applySetDefaultFilterWatcher,
    fetchAccessGroupsAutoSuggestDataWatcher,
    editAccessGroupWatcher,
    disableAccessGroupWatcher,
    addAccessGroupWatcher,
    accessGroupMenuItemsWatcher,
    fetchPlanAndMeetStatusListWatcher,
    updateMTPStatusWatcher,
    fetchNotificationsWatcher,
    fetchMTPRecommendationsWatcher,
    fetchHqsWatcher,
    filterBasedDownloadExcelWatcher,
    fetchPreferredFiltersWatcher,
    fetchMTPUnlockReasonsWatcher,
    markNotificationReadWatcher,
    enableDCRWatcher,
    fetchDcrUnlockRequestListWatcher,
    fetchDCRUnlockReasonsWatcher,
    approveDCRUnlockRequestWatcher,
    fetchUserAppSettingsWatcher,
    fetchPlanAndMeetHierarchyWatcher,
    HierarchyBasedDownloadExcelWatcher,
    fetchDCRHqsWatcher,
    dcrDownloadExcelWatcher,
    fetchPartiesBySpidWatcher,
    fetchAreasBySpidWatcher,
    fetchPartyDetailBySpidWatcher,
    fetchCloningPeriodWatcher,
    transferPositionUpdateWatcher,
    fetchPositionCloningListWatcher,
    clonePositionUpdateWatcher,
    fetchApprovalWorkflowListWatcher,
    fetchMasterConfigDataWatcher,
    createApprovalResponseWatcher,
    BulkGSPUpdateWatcher,
    updateMinApproverConfigWatcher,
    fetchInventoryCnfReturnWatcher,
    fetchInventoryIntransistChallansWatcher,
    fetchAutoSuggestionWatcher,
    fetchInTransitFilteredDataWatcher,
    postIntransitChallanOpenCloseWatcher,
    enableDisableIntransitChallansWatcher,
    fetchVoucherNumberDataWatcher,
    fetchCNFStatesAndDivisionsWatcher,
    fetchCNFHeadquartersWatcher,
    fetchCnfAutoSuggestionWatcher,
    fetchFilteredDataWatcher,
    fetchCnfReturnItemsFilterWatcher,
    fetchApprovedItemsWatcher,
    fetchRecievedChallansDataWatcher,
    fetchIntransitChallanDivivionsWatcher,
    fetchRejectItemsWatcher,
    fetchApproveReturnItemsAutoSuggestionWatcher,
    fetchInventoryConfiguratorDivisionWatcher,
    fetchInventoryConfiguratorCustomerTypeWatcher,
    fetchInventoryConfiguratorRegionHeirarcyWatcher,
    fetchInventoryConfiguratorDoctorSpecialityWatcher,
    fetchInventoryConfiguratorDoctorQualificationWatcher,
    fetchInventoryConfiguratorSpecCategoryWatcher,
    fetchInventoryConfiguratorClassCodeWatcher,
    fetchInventoryConfiguratorRolesWatcher,
    fetchInventoryConfiguratorItemCategoryWatcher,
    fetchInventoryConfiguratorItemCodeWatcher,
    fetchInventoryConfiguratorSetValueWatcher,
    fetchInventoryConfiguratorQualificationWatcher,
    modifyDCRRequestWatcher,
    webAccessUnlockWatcher,
    getWebAccessStatusWatcher,
    fetchPositionTransferListWatcher,
    validatePartyTransferWatcher,
    fetchInventoryApproveReturnWatcher,
    fetchApproveStatesAndDivisionsWatcher,
    fetchApproveHeadquartersWatcher,
    fetchApproveFilteredDataWatcher,
    fetchApproveReturnItemsFilterWatcher,
    fetchApprovedReturnItemsWatcher,
    fetchApproveRejectItemsWatcher,
    fetchExpectedUtilizatonDateSkuAutoSuggestionWatcher,
    fetchExpectedUtilizatonDaysWatcher,
    fetchNewExpectedUtilizatonDaysWatcher,
    fetchItemCategoryWatcher,
    fetchInventoryDeleteRolesWatcher,
    fetchInventorySaveRolesWatcher,
    fetchInventoryDivisionWatcher,
    fetchInventoryRolesPage2Watcher,
    fetchInventorySaveRolesPage2Watcher,
    fetchInventoryDeleteRolesPage2Watcher,
    fetchInventoryPartyTypeWatcher,
    validatePartyTransferWatcher,
    fetchInTransitAccessDivisionStateWatcher,
    fetchInTransitAccessUserWatcher,
    fetchInTransitAccessDivisionLockedWatcher,
    fetchInTransitAccessStateLockedWatcher,
    fetchInTransitAccessUserLockedWatcher,
    fetchInTransitAccessUserStateDivisionRoleWatcher,
    fetchChallanShortQtyApprovalDataListWatcher,
    validatePartyTransferWatcher,
    areaAllocationWatcher,
    unmappedActivityListWatcher,
    mappedActivityListWatcher,
    saveActivityMappingWatcher,
    fetchActivitiesWatcher,
    fetchDivisionMappingWatcher,
    fetchVoucherDetailsListWatcher,
    fetchChallanShortQtyApprovalWatcher,
    fetchChallanShortQtyRejectWatcher,
    FetchAutoSuggestionChallanWatcher,
    fetchChallanShortQuantityAuditDivivionsWatcher,
    fetchAutoSuggestionChallanShortQtyWatcher,
    fetchChallanShortQuantityAuditTrailDataWatcher,
    fetchChallanAuditTrailExcelSheetWatcher,
    fetchItemCategoryUserWatcher,
    fetchInventoryRulesActiveInActiveWatcher,
    fetchInventoryRulesActiveWatcher,
    fetchInventoryRulesInActiveWatcher,
    fetchCnfAuditDivisionWatcher,
    fetchCnfReturnAudtilDetialsWatcher,
    fetchChallanAuditDataWatcher,
    fetchChallanAuditReportWatcher,
    fetchChallanReceivesearchDataWatcher,
    saveActivitiesWatcher,
    fetchChallanReceiveAutoSuggestWatcher,
    fetchStateAndDivisionWatcher,
    fetchHeadquaterWatcher,
    fetchPendencyReportWatcher,
    fetchDivisioinStateDataWatcher,
    fetchItemSampleAutoSuggestWatcher,
    fetchPendencySubordinatesWatcher,
    fetchUserListDataWatcher,
    fetchAutoSuggestListForStaffNameWatcher,
    fetchItemAndSampleWatcher,
    enableUnfilledDCRWatcher,
    dcrBulkUnlockWatcher,
    bulkSTPUpdateWatcher,
    bulkMTPUpdateWatcher,
    bulkDCRPUpdateWatcher,
    fetchAllHierarchyWatcher,
    fetchGspScheduleListWatcher,
    validatePrerequisitesWatcher,
    saveConfigurationWatcher,
    fetchGspDivisionsWatcher,
    bulkSTPUnlockWatcher,
    bulkMTPUnlockWatcher,
    fetchDataCorrectionListWatcher,
    updateDataCorrectionWatcher,
    fetchCountryWatcher,
    fetchStateeWatcher,
    fetchHolidatByYearWatcher,
    fetchHolidayDivisionWatcher,
    fetchAllowedHolidayByCountryYearWatcher,
    fetchReportersApiWatcher,
    fetchGetAllBalanceLeaveApiWatcher,
    fetchLeaveHistoryWatcher,
  ];

  /**
   * keep everything (e.g., child tasks) alive
   *
   **/
  yield all(
    sagas.map((saga: any) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {}
        }
      }),
    ),
  );
}
