import {useState, useEffect} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {useStyles} from './styles';
import {Close} from '@mui/icons-material';
import {
  DATE_TIME_SECOND_FORMAT,
  JUMP_DROPDOWN_VALUES,
  PRODUCT_LEVEL_DROPDOWN_VALUES,
} from '@app/common/constants';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {useDispatch, useSelector} from 'react-redux';
import {gspScheduleSelector} from '../../pages/gsp-schedule/redux';
import {
  IGspDivision,
  IGspScheduleListItem,
  saveConfigurationCreator,
} from '../../pages/gsp-schedule/redux/slice';
import dayjs from 'dayjs';
import {
  getCurrentTimeFormatDate,
  getMidnightTimeFormatDate,
} from '@app/utils/dateTimeHelper';
import {Label} from '@app/components/elements/Label';

const ADD_DIVISION_SUBTEXT =
  ' Other than these configurations we also need PDC data, sales data to be configured at backend to initiate GSP for division users';

const CreateGspDivConfig = ({
  open,
  onClose,
}: Readonly<{open: boolean; onClose: any}>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [division, setDivision] = useState<any>(null);
  const [jump, setJump] = useState<any>(null);
  const [productLevelSetting, setProductLevelSetting] = useState<any>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [filteredDivisions, setFilteredDivisions] = useState<
    Array<IGspDivision>
  >([]);
  const gspDivisions = useSelector(gspScheduleSelector.getGspDivisions());
  const selectedFY = useSelector(gspScheduleSelector.getSelectedFY());
  const [startDateLimit, setStartDateLimit] = useState<any>(null);
  const [endDateLimit, setEndDateLimit] = useState<any>(null);
  const gspGridList = useSelector(
    gspScheduleSelector.getOriginalGspScheduleList(),
  );

  useEffect(() => {
    const divisions = gspDivisions.filter(
      (item: IGspDivision) => !item.isGspConfigured,
    );
    const nonExistingDivisions = divisions?.filter((item: IGspDivision) => {
      if (
        !gspGridList.find(
          (row: IGspScheduleListItem) => row.divisionId === item.id,
        )
      )
        return item;
    });
    setFilteredDivisions(nonExistingDivisions);
  }, [gspDivisions]);

  const closeDialog = () => {
    setDivision(null);
    setJump(null);
    setProductLevelSetting(null);
    setStartDate(null);
    setEndDate(null);
    setStartDateLimit(null);
    setEndDateLimit(null);
    onClose();
  };

  const createNewDivConfig = () => {
    const apiData = {
      activeFY: selectedFY,
      gspConfig: [
        {
          divisionId: division,
          powerBrandLevel: productLevelSetting,
          planningLevel: jump,
          unlockedStartDate: startDate,
          unlockedEndDate: endDate,
        },
      ],
      isNew: true,
      divisionName: filteredDivisions.find(item => item.id === division)?.name,
    };
    dispatch(saveConfigurationCreator(apiData));
    closeDialog();
  };

  const isApplyDisabled = () => {
    return (
      division &&
      jump &&
      productLevelSetting &&
      startDate &&
      endDate &&
      startDate < endDate &&
      startDate > startDateLimit &&
      endDate < endDateLimit
    );
  };

  const handleDivisionChange = (val: any) => {
    setDivision(val);
    const division = filteredDivisions?.find(div => div.id === val);
    setStartDateLimit(division?.currentYearStartDateLimit);
    setEndDateLimit(division?.currentYearEndDateLimit);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{paper: classes.dialog}}
      scroll="paper">
      <Box className={classes.boxtitle}>
        <DialogTitle className={classes.title}>Add Division</DialogTitle>
        <IconButton
          color="primary"
          aria-label="add division close"
          component="span"
          id="close-add-modal"
          onClick={closeDialog}
          data-testid={'close-add-modal'}>
          <Close />
        </IconButton>
      </Box>
      <Label
        title={ADD_DIVISION_SUBTEXT}
        classes={classes.subText}
        hideToolTip
      />

      <DialogContent>
        <FormControl size="medium" fullWidth variant="standard">
          <InputLabel id="division">Select Division</InputLabel>
          <Select
            labelId="division"
            id="division"
            value={division}
            label="Select Division"
            onChange={e => handleDivisionChange(e.target.value)}
            data-testid="divisions-dropdown">
            {filteredDivisions?.length ? (
              filteredDivisions?.map((item: IGspDivision) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  data-testid={`menu_item_${item.id}`}>
                  {item.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem
                key={null}
                disabled={true}
                data-testid={`menu_item_empty`}>
                No divisions to select
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl size="medium" fullWidth variant="standard">
          <InputLabel id="jump">Select Jump</InputLabel>
          <Select
            labelId="jump"
            id="jump"
            label="Select Jump"
            value={jump}
            onChange={e => setJump(e.target.value)}
            data-testid="jump-dropdown">
            {JUMP_DROPDOWN_VALUES?.map(item => (
              <MenuItem
                key={item}
                value={item}
                data-testid={`menu_item_${item}`}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="medium" fullWidth variant="standard">
          <InputLabel id="product-level-setting">
            Select Product Level Setting
          </InputLabel>
          <Select
            labelId="product-level-setting"
            id="product-level-setting"
            value={productLevelSetting}
            label="Select Product Level Setting"
            onChange={e => setProductLevelSetting(e.target.value)}
            data-testid="product-level-setting-dropdown">
            {PRODUCT_LEVEL_DROPDOWN_VALUES?.map(item => (
              <MenuItem
                key={item}
                value={item}
                data-testid={`menu_item_${item}`}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={startDate}
            minDate={
              startDateLimit
                ? dayjs(startDateLimit).add(1, 'days')?.toDate()
                : dayjs()
            }
            maxDate={
              endDate
                ? dayjs(endDate).toDate()
                : dayjs(endDateLimit).subtract(1, 'days')?.toDate()
            }
            inputFormat="dd-MM-yyyy"
            onChange={newValue => {
              const formattedDate = getCurrentTimeFormatDate({
                date: newValue,
                format: DATE_TIME_SECOND_FORMAT,
              });
              setStartDate(formattedDate);
            }}
            InputProps={{
              classes: {root: classes.root},
            }}
            renderInput={param => (
              <TextField
                {...param}
                className={classes.datePicker}
                inputProps={{
                  ...param.inputProps,
                  placeholder: 'Start Date',
                }}
              />
            )}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={endDate}
            minDate={
              startDate
                ? dayjs(startDate).add(1, 'days')?.toDate()
                : startDateLimit
                ? dayjs(startDateLimit).add(1, 'days')?.toDate()
                : dayjs()
            }
            maxDate={dayjs(endDateLimit).subtract(1, 'days')?.toDate()}
            inputFormat="dd-MM-yyyy"
            onChange={newValue => {
              const formattedDate = getMidnightTimeFormatDate({
                date: newValue,
                format: DATE_TIME_SECOND_FORMAT,
              });
              setEndDate(formattedDate);
            }}
            InputProps={{
              classes: {root: classes.root},
            }}
            renderInput={param => (
              <TextField
                {...param}
                className={classes.datePicker}
                inputProps={{
                  ...param.inputProps,
                  placeholder: 'End Date',
                }}
              />
            )}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Box className={classes.box}>
          <Button
            data-testid="button-cancel"
            variant="contained"
            className={classes.paperButton}
            onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            data-testid="button-apply"
            variant="contained"
            className={classes.applyButton}
            disabled={!isApplyDisabled()}
            onClick={createNewDivConfig}>
            Apply
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CreateGspDivConfig;
