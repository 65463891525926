import {combineReducers} from 'redux';
import {authTokenReducer} from '@app/screens/generic/Login/redux';
import {routeStateReducer} from '@app/router/redux';
import {filterStateReducer} from '@app/components/right-sidebar/redux';
import {appSliceReducer} from './appSlice';
import {userManagementStateReducer} from '@app/screens/access-management/pages/user-management/redux';
import {accessGroupMasterStateReducer} from '@app/screens/access-management/pages/access-group-master/redux';
import {planAndMeetStatusStateReducer} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';
import {dcrUnlockRequestStateReducer} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux/slice';
import {positionManagementStateReducer} from '@app/screens/module-specific-utilities/pages/position-management/redux';
import {approvalWorkflowStateReducer} from '@app/screens/configuration-management/pages/approval-workflow/redux/slice';
import {inventoryModuleCnfReducer} from '@app/screens/inventory-management/pages/cnf-return-items/redux';
import {intransistChallanReducer} from '@app/screens/inventory-management/pages/intransit-challan/redux';
import {inTransistAccessReducer} from '@app/screens/inventory-management/pages/in-transit-access/redux';
import {approvedReturnItemReducers} from '@app/screens/inventory-management/pages/ApprovedReturnedItems/redux';
import {inventoryManagementConfiguratorStateReducer} from '@app/screens/inventory-management/pages/inventoryConfigurator/redux';
import {expectedUtilzationDateReducers} from '@app/screens/inventory-management/pages/Expected Utilizaton Date/redux';
import {inventoryRolesReducer} from '@app/screens/inventory-management/pages/inventoryRules/redux';
import {challanShortQtyApprovalReducer} from '@app/screens/inventory-management/pages/ChallanShortQtyApproval/redux';
import {activityMappingStateReducer} from '@app/screens/configuration-management/pages/activity-mapping/redux';
import {gspScheduleStateReducer} from '@app/screens/configuration-management/pages/gsp-schedule/redux';
import {inventoryModulePRReducer} from '@app/screens/inventory-management/pages/PendencyReport/redux';
import { inventoryModuleLedgerReducer } from '@app/screens/inventory-management/pages/ledgerReport/redux';
import { dataCorrectionStateReducer } from '@app/screens/master-data-management/pages/data-correction/redux'
import {holidayManagmentReducer} from '@app/screens/holiday-management/redux';
import {leaveManagmentReducer} from '@app/screens/leave-management/redux/slice';

export const rootReducer = combineReducers({
  appState: appSliceReducer,
  authState: authTokenReducer,
  routeState: routeStateReducer,
  filterState: filterStateReducer,
  userManagement: userManagementStateReducer,
  accessGroupMaster: accessGroupMasterStateReducer,
  planAndMeetStatusMaster: planAndMeetStatusStateReducer,
  dcrUnlockRequestMaster: dcrUnlockRequestStateReducer,
  positionManagementMaster: positionManagementStateReducer,
  approvalWorkflowMaster: approvalWorkflowStateReducer,
  inventoryModule: inventoryModuleCnfReducer,
  intransistChallan: intransistChallanReducer,
  inTransistAccess: inTransistAccessReducer,
  inventoryManagementConfigurator: inventoryManagementConfiguratorStateReducer,
  approvedReturnItems: approvedReturnItemReducers,
  expectedUtilizationDate: expectedUtilzationDateReducers,
  inventoryRoles: inventoryRolesReducer,
  challanShortQtyApproval: challanShortQtyApprovalReducer,
  activityMappingMaster: activityMappingStateReducer,
  gspScheduleMaster: gspScheduleStateReducer,
  pendencyReport: inventoryModulePRReducer,
  ledgerReport: inventoryModuleLedgerReducer,
  dataCorrectionMaster: dataCorrectionStateReducer,
  holidayManagment: holidayManagmentReducer,
  leaveManagment: leaveManagmentReducer,
});

export {} from './appSlice';
